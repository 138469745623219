import React from 'react';
import ReactDOM from 'react-dom';
import Feature from './Feature';
import * as serviceWorker from './serviceWorker';
// assets
import './theme/styles.scss';
// gtm
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
    gtmId: 'GTM-52JHS4L'
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
	<Feature />,
  	document.getElementById('root')
);

serviceWorker.unregister();
