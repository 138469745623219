import React, { Component, Fragment } from 'react';
// plugins
import ImageLoader from 'react-imageloader';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Pagination]);
// components
		
let arabic = false;
if(window.location.href.indexOf('l=ar') > -1 || document.referrer.indexOf('ar.audi') > -1){
	arabic = true;
}

let region = 'me';
if(document.referrer.indexOf('azen') > -1 || document.referrer.indexOf('azar') > -1){
	region = 'az';
}else if(document.referrer.indexOf('bhen') > -1 || document.referrer.indexOf('bhar') > -1){
	region = 'bh';
}else if(document.referrer.indexOf('duen') > -1 || document.referrer.indexOf('duar') > -1){
	region = 'du';
}else if(document.referrer.indexOf('kwen') > -1 || document.referrer.indexOf('kwar') > -1){
	region = 'kw';
}else if(document.referrer.indexOf('saen') > -1 || document.referrer.indexOf('saar') > -1){
	region = 'sa';
}else if(document.referrer.indexOf('omen') > -1 || document.referrer.indexOf('omar') > -1){
	region = 'om';
}else if(document.referrer.indexOf('qaen') > -1 || document.referrer.indexOf('qaar') > -1){
	region = 'qa';
}else if(document.referrer.indexOf('joen') > -1 || document.referrer.indexOf('joar') > -1){
	region = 'jo';
}else if(document.referrer.indexOf('lben') > -1 || document.referrer.indexOf('lbar') > -1){
	region = 'lb';
}

let place;
let data,eventName;
if(window.location.href.indexOf('type=support') > -1){
	// let { support } = require(`../json/${region}.items.json`);
	// data = support;
	eventName = "fa-support-size";
	place = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAASCAIAAAA/hdJEAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACFJREFUeNpiPHv2LAPFgImBGmDUlFFTRk0ZNYVWpgAEGAAsXgKL1Dn3cAAAAABJRU5ErkJggg==';
}else {
	// let { main } = require(`../json/${region}.items.json`);
	// data = main;
	eventName = "fa-main-size";
	place = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAoCAIAAAD/iQTwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADBJREFUeNrszUENAAAIBCC1f7XrZAofblCATlLHpu45HA6Hw+FwOBwOh8Px+VgBBgAHigK3RM/HagAAAABJRU5ErkJggg==';
}

function preloader() {
  return <img src={place} className="place"/>;
}

function onElementHeightChange(elm, callback){
	var lastHeight = elm.clientHeight, newHeight;
	(function run(){
		newHeight = elm.clientHeight;
		if( lastHeight != newHeight )
			callback(newHeight)
		lastHeight = newHeight

        if( elm.onElementHeightChangeTimer )
          clearTimeout(elm.onElementHeightChangeTimer)

		elm.onElementHeightChangeTimer = setTimeout(run, 200)
	})()
}

class Feature extends Component {
	constructor(){
		super();
		this.handleResize = this.handleResize.bind(this);
		this.postSize = this.postSize.bind(this);
		this.state = {
			tablet:false,
			region:region,
			data:false
		}
		
		// pull data
		fetch(`https://home.audimiddleeast.com/json/${region}.items.json`)
		.then((response) => {
		    if(response.status >= 400){
		      	throw new Error("Bad response from server");
		    }
		    return response.json();
		})
		.then((data) => {
			if(window.location.href.indexOf('type=support') > -1){
				this.setState({ data: data.support });
			}else {
				this.setState({ data: data.main });
			}
		});
	}
	componentDidMount(){
		this.setState({tablet: window.innerWidth <= 768 ? true:false});
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('load', this.postSize);

		onElementHeightChange(document.querySelector('#root'), (h)=>{
		  	this.postSize()
		})
	}
	handleResize(){
		this.setState({tablet: window.innerWidth <= 768 ? true:false});
		// this.postSize();
	}
	postSize(){
		let size = document.querySelector('#root').clientHeight;
		window.parent.postMessage({
			event:eventName,
			size:size,
		},'*');
	}
	render(){
		const {tablet,data,once} = this.state;
		let cls;
		if(eventName === 'fa-support-size'){
			cls = 'support';
		}else {
			cls = 'main';
		}
		// pagination
		let doPage = false;
		if(data){
			let w = window.innerWidth
				|| document.documentElement.clientWidth
				|| document.body.clientWidth;
			if(w >= 1367){
				doPage = data.items.length > 3 ? data.items.length > 4 ? true : false : false;
			}else if(w >= 769){
				doPage = data.items.length > 3 ? data.items.length > 4 ? true : true : false;
			}else {
				doPage = true;
			}
		}

		return (
			<Fragment>
			{
				data ?
					<div className={`feature ${cls} ${doPage ? '' : 'pad-dot'} ${arabic ? 'arabic':''}`}>
					    <Swiper
							spaceBetween={6}
							pagination={{ clickable: false }}
							breakpoints={{
								0: {
									slidesPerView: 'auto',
							      	centeredSlides:true,
									centeredSlidesBounds:true,
								},
								541: {
									slidesPerView: 2,
							      	centeredSlides:false,
									centeredSlidesBounds:false,
								},
								769: {
									slidesPerView: 3,
									pagination: doPage ? { clickable: false }:false
								},
							    1367: {
							      	slidesPerView: data.items.length > 3 ? 4 : 3,
							      	pagination: doPage ? { clickable: false }:false
							    }
							}}
					    	>
					      	{
								data.items.map((item)=>{
									let getdata = arabic ? item.arabic : item;
									return (
										<SwiperSlide>
											<div className={`feature-item ${item.color}`}>
												<div className="banner">
													
													<ImageLoader
													    src={item.image}
													    preloader={preloader}>
													</ImageLoader>

													{
														!data.small ?
															<div className="banner-content">
																<div className="inset">
																	<h1>{getdata.name}</h1>
																	<hr/>
																	<h2>{getdata.description}</h2>
																</div>
																{
																	getdata.bar ? 
																		<div className="infobar">
																			<p>{getdata.bar}</p>
																		</div>
																	: ''
																}
															</div>
														: 
															<div className="banner-content fill">
																<a target="_parent" href={getdata.cta.link}>{getdata.cta.text}</a>
															</div> 
													}
												</div>
												{
													!data.small ? 
														<a className="cta" target="_parent" href={getdata.cta.link}>{getdata.cta.text}</a>
													: ''
												}
											</div>
										</SwiperSlide>
									)
								})
					      	}
					    </Swiper>
					    {
					    	doPage ? <div className="dots"></div> : ''
					    }
					</div>
				: 
					''
			}
			</Fragment>
		);
	}
}


export default Feature;
